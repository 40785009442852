import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.ts'), 'store/index.ts')

  // If store is an exported method = classic mode (deprecated)

  if (typeof store === 'function') {
    return console.warn('Classic mode for store/ is deprecated and will be removed in Nuxt 3.')
  }

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/account-deleted-modal/actions.ts'), 'account-deleted-modal/actions.ts')
  resolveStoreModules(require('../store/account-deleted-modal/getters.ts'), 'account-deleted-modal/getters.ts')
  resolveStoreModules(require('../store/account-deleted-modal/mutations.ts'), 'account-deleted-modal/mutations.ts')
  resolveStoreModules(require('../store/account-deleted-modal/state.ts'), 'account-deleted-modal/state.ts')
  resolveStoreModules(require('../store/account-deleted-modal/types.ts'), 'account-deleted-modal/types.ts')
  resolveStoreModules(require('../store/banner/actions.ts'), 'banner/actions.ts')
  resolveStoreModules(require('../store/banner/getters.ts'), 'banner/getters.ts')
  resolveStoreModules(require('../store/banner/mutations.ts'), 'banner/mutations.ts')
  resolveStoreModules(require('../store/banner/state.ts'), 'banner/state.ts')
  resolveStoreModules(require('../store/banner/types.ts'), 'banner/types.ts')
  resolveStoreModules(require('../store/candidate/actions.ts'), 'candidate/actions.ts')
  resolveStoreModules(require('../store/candidate/getters.ts'), 'candidate/getters.ts')
  resolveStoreModules(require('../store/candidate/mutations.ts'), 'candidate/mutations.ts')
  resolveStoreModules(require('../store/candidate/state.ts'), 'candidate/state.ts')
  resolveStoreModules(require('../store/candidate/types.ts'), 'candidate/types.ts')
  resolveStoreModules(require('../store/category/actions.ts'), 'category/actions.ts')
  resolveStoreModules(require('../store/category/getters.ts'), 'category/getters.ts')
  resolveStoreModules(require('../store/category/mutations.ts'), 'category/mutations.ts')
  resolveStoreModules(require('../store/category/state.ts'), 'category/state.ts')
  resolveStoreModules(require('../store/category/types.ts'), 'category/types.ts')
  resolveStoreModules(require('../store/categoryHashtag/actions.ts'), 'categoryHashtag/actions.ts')
  resolveStoreModules(require('../store/categoryHashtag/getters.ts'), 'categoryHashtag/getters.ts')
  resolveStoreModules(require('../store/categoryHashtag/mutations.ts'), 'categoryHashtag/mutations.ts')
  resolveStoreModules(require('../store/categoryHashtag/state.ts'), 'categoryHashtag/state.ts')
  resolveStoreModules(require('../store/categoryHashtag/types.ts'), 'categoryHashtag/types.ts')
  resolveStoreModules(require('../store/city/actions.ts'), 'city/actions.ts')
  resolveStoreModules(require('../store/city/getters.ts'), 'city/getters.ts')
  resolveStoreModules(require('../store/city/mutations.ts'), 'city/mutations.ts')
  resolveStoreModules(require('../store/city/state.ts'), 'city/state.ts')
  resolveStoreModules(require('../store/city/types.ts'), 'city/types.ts')
  resolveStoreModules(require('../store/contract/actions.ts'), 'contract/actions.ts')
  resolveStoreModules(require('../store/contract/getters.ts'), 'contract/getters.ts')
  resolveStoreModules(require('../store/contract/mutations.ts'), 'contract/mutations.ts')
  resolveStoreModules(require('../store/contract/state.ts'), 'contract/state.ts')
  resolveStoreModules(require('../store/contract/types.ts'), 'contract/types.ts')
  resolveStoreModules(require('../store/country/actions.ts'), 'country/actions.ts')
  resolveStoreModules(require('../store/country/getters.ts'), 'country/getters.ts')
  resolveStoreModules(require('../store/country/mutations.ts'), 'country/mutations.ts')
  resolveStoreModules(require('../store/country/state.ts'), 'country/state.ts')
  resolveStoreModules(require('../store/country/types.ts'), 'country/types.ts')
  resolveStoreModules(require('../store/currency/actions.ts'), 'currency/actions.ts')
  resolveStoreModules(require('../store/currency/getters.ts'), 'currency/getters.ts')
  resolveStoreModules(require('../store/currency/mutations.ts'), 'currency/mutations.ts')
  resolveStoreModules(require('../store/currency/state.ts'), 'currency/state.ts')
  resolveStoreModules(require('../store/currency/types.ts'), 'currency/types.ts')
  resolveStoreModules(require('../store/department/actions.ts'), 'department/actions.ts')
  resolveStoreModules(require('../store/department/getters.ts'), 'department/getters.ts')
  resolveStoreModules(require('../store/department/mutations.ts'), 'department/mutations.ts')
  resolveStoreModules(require('../store/department/state.ts'), 'department/state.ts')
  resolveStoreModules(require('../store/department/types.ts'), 'department/types.ts')
  resolveStoreModules(require('../store/education/actions.ts'), 'education/actions.ts')
  resolveStoreModules(require('../store/education/getters.ts'), 'education/getters.ts')
  resolveStoreModules(require('../store/education/mutations.ts'), 'education/mutations.ts')
  resolveStoreModules(require('../store/education/state.ts'), 'education/state.ts')
  resolveStoreModules(require('../store/education/types.ts'), 'education/types.ts')
  resolveStoreModules(require('../store/interest-tags/actions.ts'), 'interest-tags/actions.ts')
  resolveStoreModules(require('../store/interest-tags/getters.ts'), 'interest-tags/getters.ts')
  resolveStoreModules(require('../store/interest-tags/mutations.ts'), 'interest-tags/mutations.ts')
  resolveStoreModules(require('../store/interest-tags/state.ts'), 'interest-tags/state.ts')
  resolveStoreModules(require('../store/interest-tags/types.ts'), 'interest-tags/types.ts')
  resolveStoreModules(require('../store/language/actions.ts'), 'language/actions.ts')
  resolveStoreModules(require('../store/language/getters.ts'), 'language/getters.ts')
  resolveStoreModules(require('../store/language/mutations.ts'), 'language/mutations.ts')
  resolveStoreModules(require('../store/language/state.ts'), 'language/state.ts')
  resolveStoreModules(require('../store/language/types.ts'), 'language/types.ts')
  resolveStoreModules(require('../store/loading/actions.ts'), 'loading/actions.ts')
  resolveStoreModules(require('../store/loading/getters.ts'), 'loading/getters.ts')
  resolveStoreModules(require('../store/loading/mutations.ts'), 'loading/mutations.ts')
  resolveStoreModules(require('../store/loading/state.ts'), 'loading/state.ts')
  resolveStoreModules(require('../store/loading/types.ts'), 'loading/types.ts')
  resolveStoreModules(require('../store/login-modal/actions.ts'), 'login-modal/actions.ts')
  resolveStoreModules(require('../store/login-modal/mutations.ts'), 'login-modal/mutations.ts')
  resolveStoreModules(require('../store/login-modal/state.ts'), 'login-modal/state.ts')
  resolveStoreModules(require('../store/login-modal/types.ts'), 'login-modal/types.ts')
  resolveStoreModules(require('../store/navbar/actions.ts'), 'navbar/actions.ts')
  resolveStoreModules(require('../store/navbar/mutations.ts'), 'navbar/mutations.ts')
  resolveStoreModules(require('../store/navbar/state.ts'), 'navbar/state.ts')
  resolveStoreModules(require('../store/navbar/types.ts'), 'navbar/types.ts')
  resolveStoreModules(require('../store/notification/actions.ts'), 'notification/actions.ts')
  resolveStoreModules(require('../store/notification/getters.ts'), 'notification/getters.ts')
  resolveStoreModules(require('../store/notification/mutations.ts'), 'notification/mutations.ts')
  resolveStoreModules(require('../store/notification/state.ts'), 'notification/state.ts')
  resolveStoreModules(require('../store/notification/types.ts'), 'notification/types.ts')
  resolveStoreModules(require('../store/profile/actions.ts'), 'profile/actions.ts')
  resolveStoreModules(require('../store/profile/getters.ts'), 'profile/getters.ts')
  resolveStoreModules(require('../store/profile/mutations.ts'), 'profile/mutations.ts')
  resolveStoreModules(require('../store/profile/state.ts'), 'profile/state.ts')
  resolveStoreModules(require('../store/profile/types.ts'), 'profile/types.ts')
  resolveStoreModules(require('../store/report/actions.ts'), 'report/actions.ts')
  resolveStoreModules(require('../store/report/getters.ts'), 'report/getters.ts')
  resolveStoreModules(require('../store/report/mutations.ts'), 'report/mutations.ts')
  resolveStoreModules(require('../store/report/state.ts'), 'report/state.ts')
  resolveStoreModules(require('../store/report/types.ts'), 'report/types.ts')
  resolveStoreModules(require('../store/tag/actions.ts'), 'tag/actions.ts')
  resolveStoreModules(require('../store/tag/getters.ts'), 'tag/getters.ts')
  resolveStoreModules(require('../store/tag/mutations.ts'), 'tag/mutations.ts')
  resolveStoreModules(require('../store/tag/state.ts'), 'tag/state.ts')
  resolveStoreModules(require('../store/tag/types.ts'), 'tag/types.ts')
  resolveStoreModules(require('../store/tagHashtag/actions.ts'), 'tagHashtag/actions.ts')
  resolveStoreModules(require('../store/tagHashtag/getters.ts'), 'tagHashtag/getters.ts')
  resolveStoreModules(require('../store/tagHashtag/mutations.ts'), 'tagHashtag/mutations.ts')
  resolveStoreModules(require('../store/tagHashtag/state.ts'), 'tagHashtag/state.ts')
  resolveStoreModules(require('../store/tagHashtag/types.ts'), 'tagHashtag/types.ts')
  resolveStoreModules(require('../store/userInfo/actions.ts'), 'userInfo/actions.ts')
  resolveStoreModules(require('../store/userInfo/getters.ts'), 'userInfo/getters.ts')
  resolveStoreModules(require('../store/userInfo/mutations.ts'), 'userInfo/mutations.ts')
  resolveStoreModules(require('../store/userInfo/state.ts'), 'userInfo/state.ts')
  resolveStoreModules(require('../store/userInfo/types.ts'), 'userInfo/types.ts')

  // If the environment supports hot reloading...

  if (process.client && module.hot) {
    // Whenever any Vuex module is updated...
    module.hot.accept([
      '../store/index.ts',
      '../store/account-deleted-modal/actions.ts',
      '../store/account-deleted-modal/getters.ts',
      '../store/account-deleted-modal/mutations.ts',
      '../store/account-deleted-modal/state.ts',
      '../store/account-deleted-modal/types.ts',
      '../store/banner/actions.ts',
      '../store/banner/getters.ts',
      '../store/banner/mutations.ts',
      '../store/banner/state.ts',
      '../store/banner/types.ts',
      '../store/candidate/actions.ts',
      '../store/candidate/getters.ts',
      '../store/candidate/mutations.ts',
      '../store/candidate/state.ts',
      '../store/candidate/types.ts',
      '../store/category/actions.ts',
      '../store/category/getters.ts',
      '../store/category/mutations.ts',
      '../store/category/state.ts',
      '../store/category/types.ts',
      '../store/categoryHashtag/actions.ts',
      '../store/categoryHashtag/getters.ts',
      '../store/categoryHashtag/mutations.ts',
      '../store/categoryHashtag/state.ts',
      '../store/categoryHashtag/types.ts',
      '../store/city/actions.ts',
      '../store/city/getters.ts',
      '../store/city/mutations.ts',
      '../store/city/state.ts',
      '../store/city/types.ts',
      '../store/contract/actions.ts',
      '../store/contract/getters.ts',
      '../store/contract/mutations.ts',
      '../store/contract/state.ts',
      '../store/contract/types.ts',
      '../store/country/actions.ts',
      '../store/country/getters.ts',
      '../store/country/mutations.ts',
      '../store/country/state.ts',
      '../store/country/types.ts',
      '../store/currency/actions.ts',
      '../store/currency/getters.ts',
      '../store/currency/mutations.ts',
      '../store/currency/state.ts',
      '../store/currency/types.ts',
      '../store/department/actions.ts',
      '../store/department/getters.ts',
      '../store/department/mutations.ts',
      '../store/department/state.ts',
      '../store/department/types.ts',
      '../store/education/actions.ts',
      '../store/education/getters.ts',
      '../store/education/mutations.ts',
      '../store/education/state.ts',
      '../store/education/types.ts',
      '../store/interest-tags/actions.ts',
      '../store/interest-tags/getters.ts',
      '../store/interest-tags/mutations.ts',
      '../store/interest-tags/state.ts',
      '../store/interest-tags/types.ts',
      '../store/language/actions.ts',
      '../store/language/getters.ts',
      '../store/language/mutations.ts',
      '../store/language/state.ts',
      '../store/language/types.ts',
      '../store/loading/actions.ts',
      '../store/loading/getters.ts',
      '../store/loading/mutations.ts',
      '../store/loading/state.ts',
      '../store/loading/types.ts',
      '../store/login-modal/actions.ts',
      '../store/login-modal/mutations.ts',
      '../store/login-modal/state.ts',
      '../store/login-modal/types.ts',
      '../store/navbar/actions.ts',
      '../store/navbar/mutations.ts',
      '../store/navbar/state.ts',
      '../store/navbar/types.ts',
      '../store/notification/actions.ts',
      '../store/notification/getters.ts',
      '../store/notification/mutations.ts',
      '../store/notification/state.ts',
      '../store/notification/types.ts',
      '../store/profile/actions.ts',
      '../store/profile/getters.ts',
      '../store/profile/mutations.ts',
      '../store/profile/state.ts',
      '../store/profile/types.ts',
      '../store/report/actions.ts',
      '../store/report/getters.ts',
      '../store/report/mutations.ts',
      '../store/report/state.ts',
      '../store/report/types.ts',
      '../store/tag/actions.ts',
      '../store/tag/getters.ts',
      '../store/tag/mutations.ts',
      '../store/tag/state.ts',
      '../store/tag/types.ts',
      '../store/tagHashtag/actions.ts',
      '../store/tagHashtag/getters.ts',
      '../store/tagHashtag/mutations.ts',
      '../store/tagHashtag/state.ts',
      '../store/tagHashtag/types.ts',
      '../store/userInfo/actions.ts',
      '../store/userInfo/getters.ts',
      '../store/userInfo/mutations.ts',
      '../store/userInfo/state.ts',
      '../store/userInfo/types.ts',
    ], () => {
      // Update `root.modules` with the latest definitions.
      updateModules()
      // Trigger a hot update in the store.
      window.$nuxt.$store.hotUpdate(store)
    })
  }
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
