import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _417e4353 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _4510e234 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _bccb2a06 = () => interopDefault(import('../pages/countries/index.vue' /* webpackChunkName: "pages/countries/index" */))
const _6d55c010 = () => interopDefault(import('../pages/delete-account/index.vue' /* webpackChunkName: "pages/delete-account/index" */))
const _61361e18 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _d407d76a = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _2b32fc9f = () => interopDefault(import('../pages/forums/index.vue' /* webpackChunkName: "pages/forums/index" */))
const _7cb217df = () => interopDefault(import('../pages/invalid-url.vue' /* webpackChunkName: "pages/invalid-url" */))
const _2addc12f = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _61033180 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _0085f85c = () => interopDefault(import('../pages/posts/index.vue' /* webpackChunkName: "pages/posts/index" */))
const _34a39a4e = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _72f91f64 = () => interopDefault(import('../pages/qr-download-app.vue' /* webpackChunkName: "pages/qr-download-app" */))
const _1e0e7870 = () => interopDefault(import('../pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _58738f4f = () => interopDefault(import('../pages/resend-mail.vue' /* webpackChunkName: "pages/resend-mail" */))
const _89822274 = () => interopDefault(import('../pages/roles/index.vue' /* webpackChunkName: "pages/roles/index" */))
const _549ed1f1 = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _8276be10 = () => interopDefault(import('../pages/user-block/index.vue' /* webpackChunkName: "pages/user-block/index" */))
const _727b9542 = () => interopDefault(import('../pages/candidates/create-basic.vue' /* webpackChunkName: "pages/candidates/create-basic" */))
const _3c7a3bac = () => interopDefault(import('../pages/forums/interested-tags.vue' /* webpackChunkName: "pages/forums/interested-tags" */))
const _64b9d874 = () => interopDefault(import('../pages/forums/my-question.vue' /* webpackChunkName: "pages/forums/my-question" */))
const _32291eaa = () => interopDefault(import('../pages/forums/search.vue' /* webpackChunkName: "pages/forums/search" */))
const _397ebd5c = () => interopDefault(import('../pages/forums/tags.vue' /* webpackChunkName: "pages/forums/tags" */))
const _67e54bac = () => interopDefault(import('../pages/news/search.vue' /* webpackChunkName: "pages/news/search" */))
const _23191205 = () => interopDefault(import('../pages/password/reset.vue' /* webpackChunkName: "pages/password/reset" */))
const _740f5d4a = () => interopDefault(import('../pages/posts/create-post.vue' /* webpackChunkName: "pages/posts/create-post" */))
const _881026e4 = () => interopDefault(import('../pages/posts/search.vue' /* webpackChunkName: "pages/posts/search" */))
const _8dfb1904 = () => interopDefault(import('../pages/register/confirm.vue' /* webpackChunkName: "pages/register/confirm" */))
const _b4130302 = () => interopDefault(import('../pages/reports/my-feedback/index.vue' /* webpackChunkName: "pages/reports/my-feedback/index" */))
const _7ee255a4 = () => interopDefault(import('../pages/reports/my-report/index.vue' /* webpackChunkName: "pages/reports/my-report/index" */))
const _4cf44cfd = () => interopDefault(import('../pages/api/oauth2/oauth-error.vue' /* webpackChunkName: "pages/api/oauth2/oauth-error" */))
const _06570a24 = () => interopDefault(import('../pages/api/oauth2/callback/google.vue' /* webpackChunkName: "pages/api/oauth2/callback/google" */))
const _33231910 = () => interopDefault(import('../pages/candidates/_slug/index.vue' /* webpackChunkName: "pages/candidates/_slug/index" */))
const _297c5b57 = () => interopDefault(import('../pages/forums/_slug.vue' /* webpackChunkName: "pages/forums/_slug" */))
const _64707410 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _6a1ca6d5 = () => interopDefault(import('../pages/other-candidates/_slug/index.vue' /* webpackChunkName: "pages/other-candidates/_slug/index" */))
const _490982d2 = () => interopDefault(import('../pages/posts/_slug/index.vue' /* webpackChunkName: "pages/posts/_slug/index" */))
const _217bbb00 = () => interopDefault(import('../pages/reports/_slug.vue' /* webpackChunkName: "pages/reports/_slug" */))
const _a857a0da = () => interopDefault(import('../pages/candidates/_slug/update.vue' /* webpackChunkName: "pages/candidates/_slug/update" */))
const _3da15a2d = () => interopDefault(import('../pages/posts/_slug/raw.vue' /* webpackChunkName: "pages/posts/_slug/raw" */))
const _ccc0c3ae = () => interopDefault(import('../pages/posts/_slug/update-post.vue' /* webpackChunkName: "pages/posts/_slug/update-post" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _417e4353,
    name: "about___vi"
  }, {
    path: "/contact",
    component: _4510e234,
    name: "contact___vi"
  }, {
    path: "/countries",
    component: _bccb2a06,
    name: "countries___vi"
  }, {
    path: "/delete-account",
    component: _6d55c010,
    name: "delete-account___vi"
  }, {
    path: "/en",
    component: _61361e18,
    name: "index___en"
  }, {
    path: "/forgot-password",
    component: _d407d76a,
    name: "forgot-password___vi"
  }, {
    path: "/forums",
    component: _2b32fc9f,
    name: "forums___vi"
  }, {
    path: "/invalid-url",
    component: _7cb217df,
    name: "invalid-url___vi"
  }, {
    path: "/ja",
    component: _61361e18,
    name: "index___ja"
  }, {
    path: "/login",
    component: _2addc12f,
    name: "login___vi"
  }, {
    path: "/news",
    component: _61033180,
    name: "news___vi"
  }, {
    path: "/posts",
    component: _0085f85c,
    name: "posts___vi"
  }, {
    path: "/privacy",
    component: _34a39a4e,
    name: "privacy___vi"
  }, {
    path: "/pt",
    component: _61361e18,
    name: "index___pt"
  }, {
    path: "/qr-download-app",
    component: _72f91f64,
    name: "qr-download-app___vi"
  }, {
    path: "/reports",
    component: _1e0e7870,
    name: "reports___vi"
  }, {
    path: "/resend-mail",
    component: _58738f4f,
    name: "resend-mail___vi"
  }, {
    path: "/roles",
    component: _89822274,
    name: "roles___vi"
  }, {
    path: "/sign-up",
    component: _549ed1f1,
    name: "sign-up___vi"
  }, {
    path: "/user-block",
    component: _8276be10,
    name: "user-block___vi"
  }, {
    path: "/candidates/create-basic",
    component: _727b9542,
    name: "candidates-create-basic___vi"
  }, {
    path: "/en/about",
    component: _417e4353,
    name: "about___en"
  }, {
    path: "/en/contact",
    component: _4510e234,
    name: "contact___en"
  }, {
    path: "/en/countries",
    component: _bccb2a06,
    name: "countries___en"
  }, {
    path: "/en/delete-account",
    component: _6d55c010,
    name: "delete-account___en"
  }, {
    path: "/en/forgot-password",
    component: _d407d76a,
    name: "forgot-password___en"
  }, {
    path: "/en/forums",
    component: _2b32fc9f,
    name: "forums___en"
  }, {
    path: "/en/invalid-url",
    component: _7cb217df,
    name: "invalid-url___en"
  }, {
    path: "/en/login",
    component: _2addc12f,
    name: "login___en"
  }, {
    path: "/en/news",
    component: _61033180,
    name: "news___en"
  }, {
    path: "/en/posts",
    component: _0085f85c,
    name: "posts___en"
  }, {
    path: "/en/privacy",
    component: _34a39a4e,
    name: "privacy___en"
  }, {
    path: "/en/qr-download-app",
    component: _72f91f64,
    name: "qr-download-app___en"
  }, {
    path: "/en/reports",
    component: _1e0e7870,
    name: "reports___en"
  }, {
    path: "/en/resend-mail",
    component: _58738f4f,
    name: "resend-mail___en"
  }, {
    path: "/en/roles",
    component: _89822274,
    name: "roles___en"
  }, {
    path: "/en/sign-up",
    component: _549ed1f1,
    name: "sign-up___en"
  }, {
    path: "/en/user-block",
    component: _8276be10,
    name: "user-block___en"
  }, {
    path: "/forums/interested-tags",
    component: _3c7a3bac,
    name: "forums-interested-tags___vi"
  }, {
    path: "/forums/my-question",
    component: _64b9d874,
    name: "forums-my-question___vi"
  }, {
    path: "/forums/search",
    component: _32291eaa,
    name: "forums-search___vi"
  }, {
    path: "/forums/tags",
    component: _397ebd5c,
    name: "forums-tags___vi"
  }, {
    path: "/ja/about",
    component: _417e4353,
    name: "about___ja"
  }, {
    path: "/ja/contact",
    component: _4510e234,
    name: "contact___ja"
  }, {
    path: "/ja/countries",
    component: _bccb2a06,
    name: "countries___ja"
  }, {
    path: "/ja/delete-account",
    component: _6d55c010,
    name: "delete-account___ja"
  }, {
    path: "/ja/forgot-password",
    component: _d407d76a,
    name: "forgot-password___ja"
  }, {
    path: "/ja/forums",
    component: _2b32fc9f,
    name: "forums___ja"
  }, {
    path: "/ja/invalid-url",
    component: _7cb217df,
    name: "invalid-url___ja"
  }, {
    path: "/ja/login",
    component: _2addc12f,
    name: "login___ja"
  }, {
    path: "/ja/news",
    component: _61033180,
    name: "news___ja"
  }, {
    path: "/ja/posts",
    component: _0085f85c,
    name: "posts___ja"
  }, {
    path: "/ja/privacy",
    component: _34a39a4e,
    name: "privacy___ja"
  }, {
    path: "/ja/qr-download-app",
    component: _72f91f64,
    name: "qr-download-app___ja"
  }, {
    path: "/ja/reports",
    component: _1e0e7870,
    name: "reports___ja"
  }, {
    path: "/ja/resend-mail",
    component: _58738f4f,
    name: "resend-mail___ja"
  }, {
    path: "/ja/roles",
    component: _89822274,
    name: "roles___ja"
  }, {
    path: "/ja/sign-up",
    component: _549ed1f1,
    name: "sign-up___ja"
  }, {
    path: "/ja/user-block",
    component: _8276be10,
    name: "user-block___ja"
  }, {
    path: "/news/search",
    component: _67e54bac,
    name: "news-search___vi"
  }, {
    path: "/password/reset",
    component: _23191205,
    name: "password-reset___vi"
  }, {
    path: "/posts/create-post",
    component: _740f5d4a,
    name: "posts-create-post___vi"
  }, {
    path: "/posts/search",
    component: _881026e4,
    name: "posts-search___vi"
  }, {
    path: "/pt/about",
    component: _417e4353,
    name: "about___pt"
  }, {
    path: "/pt/contact",
    component: _4510e234,
    name: "contact___pt"
  }, {
    path: "/pt/countries",
    component: _bccb2a06,
    name: "countries___pt"
  }, {
    path: "/pt/delete-account",
    component: _6d55c010,
    name: "delete-account___pt"
  }, {
    path: "/pt/forgot-password",
    component: _d407d76a,
    name: "forgot-password___pt"
  }, {
    path: "/pt/forums",
    component: _2b32fc9f,
    name: "forums___pt"
  }, {
    path: "/pt/invalid-url",
    component: _7cb217df,
    name: "invalid-url___pt"
  }, {
    path: "/pt/login",
    component: _2addc12f,
    name: "login___pt"
  }, {
    path: "/pt/news",
    component: _61033180,
    name: "news___pt"
  }, {
    path: "/pt/posts",
    component: _0085f85c,
    name: "posts___pt"
  }, {
    path: "/pt/privacy",
    component: _34a39a4e,
    name: "privacy___pt"
  }, {
    path: "/pt/qr-download-app",
    component: _72f91f64,
    name: "qr-download-app___pt"
  }, {
    path: "/pt/reports",
    component: _1e0e7870,
    name: "reports___pt"
  }, {
    path: "/pt/resend-mail",
    component: _58738f4f,
    name: "resend-mail___pt"
  }, {
    path: "/pt/roles",
    component: _89822274,
    name: "roles___pt"
  }, {
    path: "/pt/sign-up",
    component: _549ed1f1,
    name: "sign-up___pt"
  }, {
    path: "/pt/user-block",
    component: _8276be10,
    name: "user-block___pt"
  }, {
    path: "/register/confirm",
    component: _8dfb1904,
    name: "register-confirm___vi"
  }, {
    path: "/reports/my-feedback",
    component: _b4130302,
    name: "reports-my-feedback___vi"
  }, {
    path: "/reports/my-report",
    component: _7ee255a4,
    name: "reports-my-report___vi"
  }, {
    path: "/api/oauth2/oauth-error",
    component: _4cf44cfd,
    name: "api-oauth2-oauth-error___vi"
  }, {
    path: "/en/candidates/create-basic",
    component: _727b9542,
    name: "candidates-create-basic___en"
  }, {
    path: "/en/forums/interested-tags",
    component: _3c7a3bac,
    name: "forums-interested-tags___en"
  }, {
    path: "/en/forums/my-question",
    component: _64b9d874,
    name: "forums-my-question___en"
  }, {
    path: "/en/forums/search",
    component: _32291eaa,
    name: "forums-search___en"
  }, {
    path: "/en/forums/tags",
    component: _397ebd5c,
    name: "forums-tags___en"
  }, {
    path: "/en/news/search",
    component: _67e54bac,
    name: "news-search___en"
  }, {
    path: "/en/password/reset",
    component: _23191205,
    name: "password-reset___en"
  }, {
    path: "/en/posts/create-post",
    component: _740f5d4a,
    name: "posts-create-post___en"
  }, {
    path: "/en/posts/search",
    component: _881026e4,
    name: "posts-search___en"
  }, {
    path: "/en/register/confirm",
    component: _8dfb1904,
    name: "register-confirm___en"
  }, {
    path: "/en/reports/my-feedback",
    component: _b4130302,
    name: "reports-my-feedback___en"
  }, {
    path: "/en/reports/my-report",
    component: _7ee255a4,
    name: "reports-my-report___en"
  }, {
    path: "/ja/candidates/create-basic",
    component: _727b9542,
    name: "candidates-create-basic___ja"
  }, {
    path: "/ja/forums/interested-tags",
    component: _3c7a3bac,
    name: "forums-interested-tags___ja"
  }, {
    path: "/ja/forums/my-question",
    component: _64b9d874,
    name: "forums-my-question___ja"
  }, {
    path: "/ja/forums/search",
    component: _32291eaa,
    name: "forums-search___ja"
  }, {
    path: "/ja/forums/tags",
    component: _397ebd5c,
    name: "forums-tags___ja"
  }, {
    path: "/ja/news/search",
    component: _67e54bac,
    name: "news-search___ja"
  }, {
    path: "/ja/password/reset",
    component: _23191205,
    name: "password-reset___ja"
  }, {
    path: "/ja/posts/create-post",
    component: _740f5d4a,
    name: "posts-create-post___ja"
  }, {
    path: "/ja/posts/search",
    component: _881026e4,
    name: "posts-search___ja"
  }, {
    path: "/ja/register/confirm",
    component: _8dfb1904,
    name: "register-confirm___ja"
  }, {
    path: "/ja/reports/my-feedback",
    component: _b4130302,
    name: "reports-my-feedback___ja"
  }, {
    path: "/ja/reports/my-report",
    component: _7ee255a4,
    name: "reports-my-report___ja"
  }, {
    path: "/pt/candidates/create-basic",
    component: _727b9542,
    name: "candidates-create-basic___pt"
  }, {
    path: "/pt/forums/interested-tags",
    component: _3c7a3bac,
    name: "forums-interested-tags___pt"
  }, {
    path: "/pt/forums/my-question",
    component: _64b9d874,
    name: "forums-my-question___pt"
  }, {
    path: "/pt/forums/search",
    component: _32291eaa,
    name: "forums-search___pt"
  }, {
    path: "/pt/forums/tags",
    component: _397ebd5c,
    name: "forums-tags___pt"
  }, {
    path: "/pt/news/search",
    component: _67e54bac,
    name: "news-search___pt"
  }, {
    path: "/pt/password/reset",
    component: _23191205,
    name: "password-reset___pt"
  }, {
    path: "/pt/posts/create-post",
    component: _740f5d4a,
    name: "posts-create-post___pt"
  }, {
    path: "/pt/posts/search",
    component: _881026e4,
    name: "posts-search___pt"
  }, {
    path: "/pt/register/confirm",
    component: _8dfb1904,
    name: "register-confirm___pt"
  }, {
    path: "/pt/reports/my-feedback",
    component: _b4130302,
    name: "reports-my-feedback___pt"
  }, {
    path: "/pt/reports/my-report",
    component: _7ee255a4,
    name: "reports-my-report___pt"
  }, {
    path: "/api/oauth2/callback/google",
    component: _06570a24,
    name: "api-oauth2-callback-google___vi"
  }, {
    path: "/en/api/oauth2/oauth-error",
    component: _4cf44cfd,
    name: "api-oauth2-oauth-error___en"
  }, {
    path: "/ja/api/oauth2/oauth-error",
    component: _4cf44cfd,
    name: "api-oauth2-oauth-error___ja"
  }, {
    path: "/pt/api/oauth2/oauth-error",
    component: _4cf44cfd,
    name: "api-oauth2-oauth-error___pt"
  }, {
    path: "/en/api/oauth2/callback/google",
    component: _06570a24,
    name: "api-oauth2-callback-google___en"
  }, {
    path: "/ja/api/oauth2/callback/google",
    component: _06570a24,
    name: "api-oauth2-callback-google___ja"
  }, {
    path: "/pt/api/oauth2/callback/google",
    component: _06570a24,
    name: "api-oauth2-callback-google___pt"
  }, {
    path: "/",
    component: _61361e18,
    name: "index___vi"
  }, {
    path: "/en/candidates/:slug",
    component: _33231910,
    name: "candidates-slug___en"
  }, {
    path: "/en/forums/:slug",
    component: _297c5b57,
    name: "forums-slug___en"
  }, {
    path: "/en/news/:slug",
    component: _64707410,
    name: "news-slug___en"
  }, {
    path: "/en/other-candidates/:slug",
    component: _6a1ca6d5,
    name: "other-candidates-slug___en"
  }, {
    path: "/en/posts/:slug",
    component: _490982d2,
    name: "posts-slug___en"
  }, {
    path: "/en/reports/:slug",
    component: _217bbb00,
    name: "reports-slug___en"
  }, {
    path: "/ja/candidates/:slug",
    component: _33231910,
    name: "candidates-slug___ja"
  }, {
    path: "/ja/forums/:slug",
    component: _297c5b57,
    name: "forums-slug___ja"
  }, {
    path: "/ja/news/:slug",
    component: _64707410,
    name: "news-slug___ja"
  }, {
    path: "/ja/other-candidates/:slug",
    component: _6a1ca6d5,
    name: "other-candidates-slug___ja"
  }, {
    path: "/ja/posts/:slug",
    component: _490982d2,
    name: "posts-slug___ja"
  }, {
    path: "/ja/reports/:slug",
    component: _217bbb00,
    name: "reports-slug___ja"
  }, {
    path: "/pt/candidates/:slug",
    component: _33231910,
    name: "candidates-slug___pt"
  }, {
    path: "/pt/forums/:slug",
    component: _297c5b57,
    name: "forums-slug___pt"
  }, {
    path: "/pt/news/:slug",
    component: _64707410,
    name: "news-slug___pt"
  }, {
    path: "/pt/other-candidates/:slug",
    component: _6a1ca6d5,
    name: "other-candidates-slug___pt"
  }, {
    path: "/pt/posts/:slug",
    component: _490982d2,
    name: "posts-slug___pt"
  }, {
    path: "/pt/reports/:slug",
    component: _217bbb00,
    name: "reports-slug___pt"
  }, {
    path: "/en/candidates/:slug?/update",
    component: _a857a0da,
    name: "candidates-slug-update___en"
  }, {
    path: "/en/posts/:slug/raw",
    component: _3da15a2d,
    name: "posts-slug-raw___en"
  }, {
    path: "/en/posts/:slug/update-post",
    component: _ccc0c3ae,
    name: "posts-slug-update-post___en"
  }, {
    path: "/ja/candidates/:slug?/update",
    component: _a857a0da,
    name: "candidates-slug-update___ja"
  }, {
    path: "/ja/posts/:slug/raw",
    component: _3da15a2d,
    name: "posts-slug-raw___ja"
  }, {
    path: "/ja/posts/:slug/update-post",
    component: _ccc0c3ae,
    name: "posts-slug-update-post___ja"
  }, {
    path: "/pt/candidates/:slug?/update",
    component: _a857a0da,
    name: "candidates-slug-update___pt"
  }, {
    path: "/pt/posts/:slug/raw",
    component: _3da15a2d,
    name: "posts-slug-raw___pt"
  }, {
    path: "/pt/posts/:slug/update-post",
    component: _ccc0c3ae,
    name: "posts-slug-update-post___pt"
  }, {
    path: "/candidates/:slug",
    component: _33231910,
    name: "candidates-slug___vi"
  }, {
    path: "/forums/:slug",
    component: _297c5b57,
    name: "forums-slug___vi"
  }, {
    path: "/news/:slug",
    component: _64707410,
    name: "news-slug___vi"
  }, {
    path: "/other-candidates/:slug",
    component: _6a1ca6d5,
    name: "other-candidates-slug___vi"
  }, {
    path: "/posts/:slug",
    component: _490982d2,
    name: "posts-slug___vi"
  }, {
    path: "/reports/:slug",
    component: _217bbb00,
    name: "reports-slug___vi"
  }, {
    path: "/candidates/:slug?/update",
    component: _a857a0da,
    name: "candidates-slug-update___vi"
  }, {
    path: "/posts/:slug/raw",
    component: _3da15a2d,
    name: "posts-slug-raw___vi"
  }, {
    path: "/posts/:slug/update-post",
    component: _ccc0c3ae,
    name: "posts-slug-update-post___vi"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
